<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import ChatCommentInput from '~/common/components/organisms/chat-comment-input.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useAttachments } from '~/project-management/composables/pm-attachments.composable.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

defineProps({
  type: {
    type: String,
    default: 'comment',
    validator: val => ['comment', 'reply'].includes(val),
  },
  activity_uid: {
    type: String,
    required: true,
  },
  comment: {
    type: Object,
    default: null,
  },
  chat_store: {
    type: Object,
    required: true,
  },
  comment_input_config: {
    type: Object,
    default: () => ({}),
  },
  user: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'close',
  'input',
  'reply',
  'send',
  'showMoreOptions',
]);

const route = useRoute();

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { addAttachmentsToActivity } = useAttachments();

const { get_asset } = common_store;
const { active_schedule, active_task_uid, activity_pusher_channel, is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  presence_members: [],
})

const asset_id = computed(() => {
  return route.params.asset_id;
});

const append_mentions_to = computed(() => {
  return is_fullscreen.value
    ? document?.getElementById?.('pm-fullscreen-container')
    : null;
});

async function onAddAttachmentsClicked() {
  if (is_fullscreen.value) {
    await document.exitFullscreen();
    await new Promise(resolve => setTimeout(resolve, 10));
  }
}

async function uploadAttachments({ attachments }) {
  addAttachmentsToActivity(attachments);
}

onMounted(() => {
  activity_pusher_channel.value.bind('pusher:subscription_succeeded', (members) => {
    state.presence_members = Object.values(members.members);
  });

  activity_pusher_channel.value.bind('pusher:member_added', (member) => {
    state.presence_members.push({ uid: member.id });
  });

  activity_pusher_channel.value.bind('pusher:member_removed', (member) => {
    state.presence_members = state.presence_members.filter((m) => m.uid !== member.id);
  });
});
</script>

<template>
  <ChatCommentInput
    :comment_input_config="comment_input_config"
    :type="type"
    :comment="comment"
    :chat_store="chat_store"
    :asset_id="asset_id"
    :user="user"
    :attachment_config="{ meta: { service: 'pm', id: activity_uid } }"
    :append_mentions_to="append_mentions_to"
    :add_attachments_click="onAddAttachmentsClicked"
    module="pm"
    :custom_message_properties="{
      asset: route.params.asset_id,
      asset_name: get_asset(route.params.asset_id)?.name,
      schedule: active_schedule.uid,
      serviceRef: active_task_uid,
      secondaryRef: active_schedule.uid,
      presence_members: state.presence_members,
    }"
    @upload-attachments="uploadAttachments"
    @comment-sent="emit('send')"
    @input="$event => emit('input', $event)"
    @show-more-options="emit('showMoreOptions')"
    @close="emit('close')"
    @reply="emit('reply')"
    @keyup.enter.prevent.stop
  >
    <template #more_options>
      <slot name="more_options" />
    </template>
  </ChatCommentInput>
</template>
