<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task, is_fullscreen } = storeToRefs(project_management_store);
const { update_activity_progress } = project_management_store;

const state = reactive({
  task_data: {
    progress: Math.round((active_task.value?.progress || 0) * 100),
    data_date: new Date(),
    actual_start_date: null,
    actual_finish_date: null,
  },
  is_loading: false,
  // We used to have min_progress earlier, set to Math.round((active_task.value?.progress || 0) * 100)
});

const prevent_save = computed(() => {
  return !state.task_data.data_date;
});

const is_start_date_field_visible = computed(() => {
  return (active_task.value.progress === 0 && state.task_data.progress <= 100);
});

const is_finish_date_field_visible = computed(() => {
  return (active_task.value.progress >= 0 && state.task_data.progress === 100);
});

async function onSave() {
  state.is_loading = true;
  const payload = { ...state.task_data };
  payload.progress = payload.progress / 100;

  if (!is_start_date_field_visible.value || payload.actual_start_date === null)
    delete payload.actual_start_date;
  else
    payload.actual_start_date = dayjs(payload.actual_start_date).format('YYYY-MM-DD');

  if (!is_finish_date_field_visible.value || payload.actual_finish_date === null)
    delete payload.actual_finish_date;
  else
    payload.actual_finish_date = dayjs(payload.actual_finish_date).format('YYYY-MM-DD');

  if (dayjs(payload.data_date).isSame(dayjs(), 'day'))
    delete payload.data_date;
  else
    payload.data_date = dayjs(payload.data_date).format('YYYY-MM-DD');

  await update_activity_progress(payload);
  state.is_loading = false;
  emit('close');
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.task_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: {
          container: 12,
          label: 3,
          wrapper: 9,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="text-lg text-gray-900 font-semibold">
              {{ $t('Update progress') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <QuantityElement
            name="progress"
            input-type="number"
            :default="state.task_data.progress"
            :value="state.task_data.progress"
            :presets="['supress_errors']"
            :rules="[
              'numeric',
              'required',
            ]"
            :min="0"
            :max="100"
            :step="1"
            :columns="{
              default: { container: 12, label: 12, wrapper: 4 },
              sm: { container: 12, label: 12, wrapper: 4 },
              md: { container: 12, label: 12, wrapper: 4 },
            }"
            autocomplete="off"
            :label="$t('Progress')"
            class="mb-6"
          />
          <DateTimeElement
            name="data_date"
            :rules="['required']"
            :label="$t('Data date')"
            :description="$t('Select the date for which the progress should be recorded.')"
            :columns="{ container: 12, label: 3, wrapper: 9 }"
            :class="{ 'mb-6': is_start_date_field_visible || is_finish_date_field_visible }"
            :options="{
              'min-date': active_task?.data_date,
              'max-date': new Date(),
              'teleport': !is_fullscreen,
              'format': 'dd MMMM yyyy',
            }"
          />
          <DateTimeElement
            v-show="is_start_date_field_visible"
            name="actual_start_date"
            :description="$t(`Choose a date only in case if it's different from the data date`)"
            :label="$t('Actual start date')"
            :columns="{ container: 12, label: 3, wrapper: 9 }"
            :class="{ 'mb-6': is_finish_date_field_visible }"
            :options="{
              teleport: !is_fullscreen,
              format: 'dd MMMM yyyy',
            }"
          />
          <DateTimeElement
            v-show="is_finish_date_field_visible"
            name="actual_finish_date"
            :description="$t(`Choose a date only in case if it's different from the data date`)"
            :label="$t('Actual finish date')"
            :columns="{ container: 12, label: 3, wrapper: 9 }"
            :options="{
              teleport: !is_fullscreen,
              format: 'dd MMMM yyyy',
            }"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <Vueform size="sm">
              <div class="flex justify-end w-full col-span-full">
                <ButtonElement
                  name="cancel"
                  class="mr-4"
                  :secondary="true"
                  @click="emit('close')"
                >
                  {{ $t('Cancel') }}
                </ButtonElement>
                <ButtonElement
                  name="save"
                  :disabled="prevent_save"
                  :loading="state.is_loading"
                  @click="onSave"
                >
                  {{ $t('Update') }}
                </ButtonElement>
              </div>
            </vueform>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
