<script setup>
// --------------------------------- Imports -------------------------------- //
import dayjs from 'dayjs';
import { generateActivityUid } from '~/project-management/utils/pm-helper.utils';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  onTaskCreated: {
    type: Function,
    required: true,
  },
  onSubtaskAdded: {
    type: Function,
    required: true,
  },
  modifyConfig: {
    type: Function,
    required: true,
  },
  translate: {
    type: Function,
    required: true,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close', 'selectTask']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  disabled_controls: [],
});

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
function onActivityDetailsClick() {
  window.gantt.$triggerActivityDetails(props.task);
  emit('close');
}

function createTask(payload, parent, index) {
  const new_index = index ?? window.gantt.$g.getTaskIndex(props.task.id) + 1;
  const uid = generateActivityUid();
  const new_task_id = window.gantt.$g.addTask({
    id: uid,
    uid,
    start_date: props.task.start_date,
    end_date: dayjs(props.task.start_date).isSame(props.task.end_date, 'day')
      ? dayjs(props.task.start_date).add(1, 'day').toDate()
      : props.task.end_date,
    duration: props.task.duration,
    ...payload,
  }, parent, new_index);
  props.onTaskCreated(window.gantt.$g.getTask(new_task_id));

  emit('selectTask', new_task_id);

  emit('close');
  return new_task_id;
}

function onAddASubtaskClick() {
  window.gantt.$g.getTask(props.task.id).$open = true;
  props.modifyConfig({ key: 'wbs_level', value: Number.MAX_SAFE_INTEGER });
  props.modifyConfig({ key: 'wbs_level_max', value: Number.MAX_SAFE_INTEGER });
  const active_node = window.gantt.$g?.ext?.keyboardNavigation?.getActiveNode?.();
  window.gantt.$g.getTask(props.task.id).type = window.gantt.$g.config.types.project;
  window.gantt.$g.getTask(props.task.id)._original_type = window.gantt.$g.config.types.wbs;

  const children = window.gantt.$g.getChildren(props.task.id);
  const new_task_id = createTask({
    text: '',
    type: window.gantt.$g.config.types.task,
    unscheduled: false,
    readonly: false,
    status: 'Not started',
    is_backend_save_pending: true,
  }, props.task.id, 0);
  gantt.$addTaskOrMilestone(window.gantt.$g.config.types.task, null, new_task_id, !children.length, true);

  const parent = window.gantt.$g.getParent(new_task_id);
  props.onSubtaskAdded(window.gantt.$g.getTask(parent));
  window.gantt.$g.ext.keyboardNavigation.focus(active_node);
}

function onAddATaskClick(position = 'above') {
  let index;
  if (position === 'above') {
    index = window.gantt.$g.getTaskIndex(props.task.id);
  }
  else if (position === 'below') {
    index = window.gantt.$g.getTaskIndex(props.task.id) + 1;
  }
  const new_task_id = createTask({
    text: '',
    type: window.gantt.$g.config.types.task,
  }, props.task.parent, index);
  gantt.$addTaskOrMilestone(window.gantt.$g.config.types.task, null, new_task_id, false, true);
}

function onAddAMilestoneClick(position = 'above') {
  let index;
  if (position === 'above') {
    index = window.gantt.$g.getTaskIndex(props.task.id);
  }
  else if (position === 'below') {
    index = window.gantt.$g.getTaskIndex(props.task.id) + 1;
  }
  const new_task_id = createTask({
    text: '',
    type: window.gantt.$g.config.types.milestone,
  }, props.task.parent, index);
  gantt.$addTaskOrMilestone(window.gantt.$g.config.types.milestone, null, new_task_id, false, true);
}

function onIndentClick() {
  window.gantt.$indentOrOutdentTask('indent', undefined, props.task.id);
  emit('close');
}

function onOutdentClick() {
  window.gantt.$outdentByMove(props.task.id);
  emit('close');
}

function onConvertToTaskClick() {
  const task = window.gantt.$g.getTask(props.task.id);
  task.type = window.gantt.$g.config.types.task;
  task._original_type = window.gantt.$g.config.types.task;
  task.duration = 1;
  task.end_date = window.gantt.$g.calculateEndDate(task.start_date, task.duration);
  window.gantt.$g.updateTask(props.task.id);
  toggleMenuItems();
  emit('close');
}

function onConvertToMilestoneClick() {
  if (props.task.progress < 1)
    window.gantt.$g.getTask(props.task.id).progress = 0;
  window.gantt.$g.getTask(props.task.id).type = window.gantt.$g.config.types.milestone;
  window.gantt.$g.getTask(props.task.id)._original_type = window.gantt.$g.config.types.milestone;
  window.gantt.$g.updateTask(props.task.id);
  toggleMenuItems();
  emit('close');
}

function onDeleteClick() {
  window.gantt.$openDeleteTaskPopup(props.task);
  emit('close');
}

function toggleMenuItems() {
  state.disabled_controls = [];
  const task = window.gantt.$g.getTask(props.task.id);
  if (task.type === window.gantt.$g.config.types.milestone)
    state.disabled_controls.push('convert_to_milestone');
  else if (task.type === window.gantt.$g.config.types.task)
    state.disabled_controls.push('convert_to_task');
  else if (task.type === window.gantt.$g.config.types.project)
    state.disabled_controls.push(...['add_sibling_task', 'add_sibling_milestone', 'convert_to_task', 'convert_to_milestone']);

  if (task._original_type === window.gantt.$g.config.types.project)
    state.disabled_controls.push('delete');
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(toggleMenuItems);
</script>

<template>
  <div class="shadow-xl flex flex-col text-xs bg-white border rounded-md cursor-pointer pm_gantt_context_menu min-w-44 p-1">
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onActivityDetailsClick">
      <IconHawkShareFour class="w-3 h-3 mr-1.5" />
      {{ props.translate('Activity details') }}
    </div>
    <div class="h-px my-1 bg-gray-200" />
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddASubtaskClick">
      <IconHawkCornerDownRight class="w-3 h-3 mr-1.5" />
      {{ props.translate('Add subtask') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_task')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddATaskClick('above')">
      <IconHawkArrowSquareUp class="w-3 h-3 mr-1.5" />
      {{ props.translate('Add task above') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_task')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddATaskClick('below')">
      <IconHawkArrowSquareDown class="w-3 h-3 mr-1.5" />
      {{ props.translate('Add task below') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_milestone')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddAMilestoneClick('above')">
      <IconHawkArrowSquareUpLeft class="w-3 h-3 mr-1.5 rotate-45" />
      {{ props.translate('Add milestone above') }}
    </div>
    <div v-if="!state.disabled_controls.includes('add_sibling_milestone')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onAddAMilestoneClick('below')">
      <IconHawkArrowSquareDownRight class="w-3 h-3 mr-1.5 rotate-45" />
      {{ props.translate('Add milestone below') }}
    </div>
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onIndentClick">
      <IconHawkLeftIndentOne class="w-3 h-3 mr-1.5" />
      {{ props.translate('Indent') }}
    </div>
    <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onOutdentClick">
      <IconHawkRightIndentOne class="w-3 h-3 mr-1.5" />
      {{ props.translate('Outdent') }}
    </div>
    <div v-if="!state.disabled_controls.includes('convert_to_task')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onConvertToTaskClick">
      <IconHawkCryptocurrencyTwo class="w-3 h-3 mr-1.5 rotate-45" />
      {{ props.translate('Convert to task') }}
    </div>
    <div v-if="!state.disabled_controls.includes('convert_to_milestone')" class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onConvertToMilestoneClick">
      <IconHawkCryptocurrencyTwo class="w-3 h-3 mr-1.5" />
      {{ props.translate('Convert to milestone') }}
    </div>
    <template v-if="!state.disabled_controls.includes('delete')">
      <div class="h-px my-1 bg-gray-200" />
      <div class="flex items-center px-2 py-1.75 rounded hover:bg-gray-50" @click="onDeleteClick">
        <IconHawkDeletePopup class="w-3 h-3 mr-1.5" />
        {{ props.translate('Delete') }}
      </div>
    </template>
  </div>
</template>
