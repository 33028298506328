export const properties_non_date = [
  '_original_type',
  'absolute_remaining_duration',
  'absolute_work_rate',
  'activity_code_values',
  'activity_code_values_map',
  'actual_cost',
  'actual_duration',
  'actual_qty',
  'actual_work',
  'actual_work_rate',
  'assignees',
  'attachments',
  'auto_progress_sync',
  'bl_duration',
  'bl_work_rate',
  'calendar_id',
  'category',
  'comments',
  'constraint_date',
  'constraint_type',
  'cost',
  'cpi',
  'created_at',
  'custom_field_values',
  'data_date',
  'duration',
  'earned_value',
  'editable',
  'field_quantities',
  'field_quantities_percentage',
  'finish_status',
  'free_slack',
  'id',
  'intial_progress',
  'is_critical',
  'is_draft',
  'is_milestone',
  'is_root',
  'original_activity',
  // 'parent', // NOTE: parent isn't to be added here ever. May the spirits of v1 watch over us.
  'percent_schedule_complete',
  'percent_schedule_complete_progress',
  'percent_work_complete',
  'planned_cost',
  'planned_duration',
  'planned_qty',
  'planned_value',
  'planned_work',
  'planned_work_rate',
  'predecessors',
  'priority',
  'progress',
  'progress_type',
  'references',
  'remaining_cost',
  'remaining_duration',
  'remaining_work',
  'remaining_work_rate',
  'resources',
  'resources_planned_cost',
  'schedule',
  'server_duration',
  'spi',
  'start_status',
  'state',
  'status',
  'successors',
  'tags',
  'text',
  'total_slack',
  'tracked',
  'type',
  'uid',
  'units',
  'updated_at',
  'wbs_path',
  'weight',
  'work',
  'work_rate',
  'work_rate_rollup_level',
];

export const properties_for_dates_start = [
  'start_date',
  'planned_start',
  'actual_start',
  'bl_start',
];

export const properties_for_dates_finish = [
  'end_date',
  'planned_finish',
  'actual_finish',
  'bl_finish',
  'finish',
];

export const properties_all = [
  ...properties_non_date,
  ...properties_for_dates_start,
  ...properties_for_dates_finish,
];
