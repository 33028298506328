<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  dependencies: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['add-dependency', 'edit-dependency']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { update_active_schedule, set_active_task_uid } = project_management_store;
const { $g, active_schedule, is_schedule_dynamic, is_schedule_editable } = storeToRefs(project_management_store);

const state = reactive({
  are_dependencies_open: true,
});

const predecessors = computed(() => props.dependencies.filter(item => !!item).filter(item => item.source_or_target === 'target'));
const successors = computed(() => props.dependencies.filter(item => !!item).filter(item => item.source_or_target === 'source'));

function editDependency(item) {
  // eslint-disable-next-line vue/custom-event-name-casing
  emit('edit-dependency', item);
}

function removeDependency(item) {
  active_schedule.value.relations = active_schedule.value.relations.filter(
    link => link.uid !== item.link_uid,
  );
  $g.value.deleteLink(item.link_id);
}

function clickDependency(item) {
  set_active_task_uid(item.uid);
  $g.value.focus();
  setTimeout(() => {
    $g.value.ext.keyboardNavigation.focus({ id: item.id, column: 'text', type: 'taskCell' });
  });
  update_active_schedule();
}

function getDependencyDescription(type) {
  if (type === 'FS')
    return `${$t('Finish to Start')}: ${$t('The target task can\'t start before the source task ends (but it may start later).')}`;
  else if (type === 'SS')
    return `${$t('Start to Start')}: ${$t('The target task can\'t start until the source task starts (but it may start later).')}`;
  else if (type === 'FF')
    return `${$t('Finish to Finish')}: ${$t('The target task can\'t end before the source task ends (but it may end later).')}`;
  else if (type === 'SF')
    return `${$t('Start to Finish')}: ${$t('The target task can\'t end before the source task starts (but it may end later).')}`;
}
</script>

<template>
  <div v-if="dependencies.length" class="py-4 mt-2">
    <div class="flex items-center gap-2">
      <div class="flex items-center cursor-pointer" @click="state.are_dependencies_open = !state.are_dependencies_open">
        <IconHawkChevronDown v-if="state.are_dependencies_open" class="inline" />
        <IconHawkChevronRight v-else class="inline" />
        <span class="ml-1 text-sm font-semibold text-gray-900">
          {{ $t('Dependencies') }}
        </span>
      </div>
      <div
        v-if="is_schedule_editable"
        class="flex items-center justify-center w-5 h-5 rounded-full cursor-pointer bg-primary-50 hover:bg-primary-100"
        @click="emit('add-dependency')"
      >
        <IconHawkPlus class="w-3 h-3 text-primary-700" />
      </div>
    </div>
    <div v-if="state.are_dependencies_open" class="mt-2 ml-4">
      <div>
        <div v-if="is_schedule_dynamic" class="mt-4 mb-2 ml-2 w-[calc(100%-16px)] border border-gray-300 bg-gray-25 flex items-center gap-3 rounded-lg p-4">
          <IconHawkInfoCircle class="text-gray-600" />
          <div class="text-sm font-normal text-gray-700">
            {{ $t('Some dependencies may be missing as the schedule is loaded on-demand.') }}
          </div>
        </div>
        <HawkAccordion
          :items="[
            ...(predecessors.length ? [{
              uid: 1,
              title: `Predecessors (${predecessors.length})`,
              dependencies: predecessors,
            }] : []),
            ...(successors.length ? [{
              uid: 2,
              title: `Successors (${successors.length})`,
              dependencies: successors,
            }] : []),
          ]"
          class="mt-4"
        >
          <template #content="{ data }">
            <div class="pt-3">
              <div
                v-for="item in data.dependencies"
                :key="item"
                class="cursor-pointer group"
                @click="clickDependency(item)"
              >
                <div class="flex justify-between text-xs h-[56px] items-center pl-3 -ml-1 hover:bg-gray-50 rounded-lg">
                  <div class="flex items-center gap-6">
                    <IconHawkPmStartToStartSource
                      v-if="item?.type === 'SS' && item?.source_or_target === 'source'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmStartToStartTarget
                      v-if="item?.type === 'SS' && item?.source_or_target === 'target'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmStartToFinishSource
                      v-else-if="item?.type === 'SF' && item?.source_or_target === 'source'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmStartToFinishTarget
                      v-else-if="item?.type === 'SF' && item?.source_or_target === 'target'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmFinishToStartSource
                      v-else-if="item?.type === 'FS' && item?.source_or_target === 'source'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmFinishToStartTarget
                      v-else-if="item?.type === 'FS' && item?.source_or_target === 'target'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmFinishToFinishSource
                      v-else-if="item?.type === 'FF' && item?.source_or_target === 'source'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <IconHawkPmFinishToFinishTarget
                      v-else-if="item?.type === 'FF' && item?.source_or_target === 'target'"
                      v-tippy="getDependencyDescription(item?.type)"
                      class="inline w-12 h-8"
                    />
                    <div class="flex flex-col">
                      <div class="flex items-center gap-2">
                        <div class="text-sm font-medium text-gray-900">
                          {{ item?.wbs }}
                        </div>
                        <div
                          v-tippy="(item?.name?.length + item?.id?.length) > 30 ? `${item?.name} (${item?.id})` : ''"
                          class="max-w-80 text-sm font-medium text-gray-900 truncate"
                        >
                          {{ item?.name }} ({{ item?.id }})
                        </div>
                        <HawkBadge v-tippy="getDependencyDescription(item?.type)">
                          {{ item?.type }}
                        </HawkBadge>
                      </div>
                      <div class="text-sm text-gray-600 font-normal">
                        {{ item?.status }} •
                        <span class="font-medium">
                          {{ item?.lag }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="is_schedule_editable"
                    class="flex justify-end pr-2 text-gray-600 invisible group-hover:visible"
                  >
                    <div
                      class="flex items-center rounded-full hover:bg-gray-200 p-1.5 cursor-pointer"
                      @click.stop="editDependency(item)"
                    >
                      <IconHawkPencilOne />
                    </div>
                    <div
                      class="flex items-center rounded-full hover:bg-gray-200 p-1.5 cursor-pointer"
                      @click.stop="removeDependency(item)"
                    >
                      <IconHawkTrashOne />
                    </div>
                  </div>
                </div>
                <hr class="mx-2 border-gray-200">
              </div>
            </div>
          </template>
        </HawkAccordion>
      </div>
    </div>
  </div>
</template>
