<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { highlightElement, waitForElement } from '~/common/utils/common.utils';

const props = defineProps({
  comments: {
    type: Array,
    required: true,
  },
  popover_position: {
    type: String,
    default: 'right',
    validator(value) {
      return ['left', 'middle', 'right'].includes(value);
    },
  },
});

const popover_pos_target = ref(null);

const state = reactive({
  open: false,
  popover_position_class: 'right-0 top-0',
});

const popover_panel_classes = computed(() => {
  const classes = [];
  switch (props.popover_position) {
    case 'left':
      classes.push('');
      break;
    case 'middle':
      classes.push('left-1/2 -translate-x-1/2');
      break;
    case 'right':
      classes.push('right-0');
      break;
  }

  return classes;
});

function togglePopover(value) {
  const current_position = popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class
    = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  state.popover_position_class = `${y_class}`;

  state.open = value;
}

function onCommentClick(comment) {
  state.open = false;
  const escape_id = CSS.escape(comment.id);
  waitForElement(`#${escape_id}`).then((element) => {
    highlightElement(element, ['!-ml-3', '!pl-3', '!-mr-3', '!pr-3']);
  });
}
</script>

<template>
  <Popover
    class="relative flex items-center"
    @mouseenter="togglePopover(true)"
    @mouseleave="togglePopover(false)"
    @click="togglePopover(!state.open)"
  >
    <PopoverButton>
      <HawkBadge
        v-if="comments?.length"
        color="yellow"
      >
        <IconHawkPinned class="text-warning-400" />
        {{ comments?.length }}
      </HawkBadge>
    </PopoverButton>
    <span
      ref="popover_pos_target"
      class="absolute top-0 left-0 w-full h-full pointer-events-none"
    />
    <div v-if="state.open">
      <PopoverPanel
        static
        class="absolute z-1 w-[350px] max-h-[400px] scrollbar bg-white border rounded-lg flex flex-col"
        :class="[state.popover_position_class, popover_panel_classes]"
      >
        <div v-for="comment in comments" :key="comment" class="w-full">
          <HawkPlainComment
            comment_type="comment"
            :enable_editor="false"
            :current_comment="comment"
            class="w-full hover:bg-gray-50 cursor-pointer p-3 rounded-lg"
            @click="onCommentClick(comment)"
          />
        </div>
      </PopoverPanel>
    </div>
  </Popover>
</template>
