<script setup>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import DOMPurify from 'dompurify';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';
import { highlightElement, waitForElement } from '~/common/utils/common.utils';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

dayjs.extend(isBetween);

const $t = inject('$t');
const $date = inject('$date');
const $services = inject('$services');

const project_management_store = useProjectManagementStore();
const auth_store = useAuthStore();
const { set_active_task_uid } = project_management_store;
const { $g, active_schedule, is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  is_loading: false,
  all_comments: [],
  grouped_attachments: {},
  filtered_comments: [],
  date_range: [],
  left_menu_selection: 'all_comments',
  right_menu_selection: 'all_time',
});

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    onClose() {
      state.right_menu_selection = 'all_time';
      date_range_modal.close();
    },
    onOkay(range) {
      state.date_range = [dayjs(range[0]).startOf('day'), dayjs(range[1]).endOf('day')];
      date_range_modal.close();
    },
  },
});

const left_menu_items = computed(() => {
  return [
    {
      label: $t('All comments'),
      uid: 'all_comments',
      on_click: () => {
        state.left_menu_selection = 'all_comments';
      },
    },
    {
      label: $t('Mentions'),
      uid: 'mentions',
      on_click: () => {
        state.left_menu_selection = 'mentions';
      },
    },
    {
      label: $t('Assigned to me'),
      uid: 'assigned_to_me',
      on_click: () => {
        state.left_menu_selection = 'assigned_to_me';
      },
    },
  ];
});

const right_menu_items = computed(() => {
  return [
    {
      label: $t('All time'),
      uid: 'all_time',
      on_click: () => {
        state.date_range = null;
        state.right_menu_selection = 'all_time';
      },
    },
    {
      label: $t('Today'),
      uid: 'today',
      on_click: () => {
        state.date_range = [dayjs().startOf('day'), dayjs().endOf('day')];
        state.right_menu_selection = 'today';
      },
    },
    {
      label: $t('Yesterday'),
      uid: 'yesterday',
      on_click: () => {
        state.date_range = [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')];
        state.right_menu_selection = 'yesterday';
      },
    },
    {
      label: $t('This week'),
      uid: 'this_week',
      on_click: () => {
        state.date_range = [dayjs().startOf('week'), dayjs().endOf('week')];
        state.right_menu_selection = 'this_week';
      },
    },
    {
      label: $t('Last week'),
      uid: 'last_week',
      on_click: () => {
        state.date_range = [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')];
        state.right_menu_selection = 'last_week';
      },
    },
    {
      label: $t('This month'),
      uid: 'this_month',
      on_click: () => {
        state.date_range = [dayjs().startOf('month'), dayjs().endOf('month')];
        state.right_menu_selection = 'this_month';
      },
    },
    {
      label: $t('Last month'),
      uid: 'last_month',
      on_click: () => {
        state.date_range = [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')];
        state.right_menu_selection = 'last_month';
      },
    },
    {
      label: $t('Custom'),
      uid: 'custom',
      on_click: () => {
        state.right_menu_selection = 'custom';
        date_range_modal.patchOptions({
          attrs: {
            options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
          },
        });
        date_range_modal.open();
      },
    },
  ];
});

const query_params = computed(() => {
  const query = {
    secondary_ref: active_schedule.value.uid,
  };

  if (state.left_menu_selection === 'mentions') {
    query.mentioned = auth_store.logged_in_user_details.user_id;
  }

  if (state.right_menu_selection !== 'all_time') {
    query.created_at_start = state.date_range[0].toISOString();
    query.created_at_end = state.date_range[1].toISOString();
  }

  return query;
});

async function onClick(activity_uid) {
  set_active_task_uid(activity_uid);
  try {
    await waitForElement('#comments').then(async (element) => {
      setTimeout(() => {
        highlightElement(
          element,
          ['!-ml-3', '!pl-3', '!-mr-3', '!pr-5'],
          { behavior: 'smooth', block: 'end', inline: 'nearest' },
        );
      }, 200);
    });
  }
  catch (error) {
    logger.error('Error while waiting for comments element', error);
  }
}

function getActivityWBSCode(activity_uid) {
  const activity_id = active_schedule.value.activities[activity_uid].id;
  return $g.value.getWBSCode($g.value.getTask(activity_id));
}

function getActivityText(activity_uid) {
  return active_schedule.value.activities[activity_uid].text;
}

function isUnread(activity_uid) {
  const activity = active_schedule.value.activities[activity_uid];

  if (activity.comments?.has_unread_mentions)
    return 'unread_mentions';
  else if (activity.comments?.has_unread_comments)
    return 'unread_comments';
  else return false;
}

watchEffect(() => {
  if (props.active === true) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);
  }
});

watch(() => props.active, async () => {
  if (props.active) {
    state.is_loading = true;
    state.left_menu_selection = 'all_comments';
    state.right_menu_selection = 'all_time';

    try {
      const { data: attachments_data } = await $services.project_management.post({
        url: 'project-management/schedules',
        id: active_schedule.value.uid,
        attribute: 'activities/attachments-details',
        body: {
          attached_to_type: 'comment',
        },
      });
      state.grouped_attachments = {};
      attachments_data.data.forEach((activity) => {
        activity.attachments.forEach((attachment) => {
          if (!state.grouped_attachments[attachment.attached_to.uid]) {
            state.grouped_attachments[attachment.attached_to.uid] = [];
          }
          state.grouped_attachments[attachment.attached_to.uid].push(attachment);
        });
      });

      const { data } = await $services.tasks.post({
        url: 'tasks/comments',
        query: query_params.value,
      });

      state.all_comments = data.data;

      state.filtered_comments = state.all_comments;
    }
    catch (error) {
      logger.error(error);
      state.all_comments = [];
      state.filtered_comments = [];
    }
    finally {
      state.is_loading = false;
    }
  }
});

watch(() => state.left_menu_selection, async () => {
  if (['all_comments', 'mentions'].includes(state.left_menu_selection)) {
    state.is_loading = true;
    const { data } = await $services.tasks.post({
      url: 'tasks/comments',
      query: query_params.value,
    });
    state.filtered_comments = data.data;
    state.is_loading = false;
  }
  else {
    state.filtered_comments = Object.keys(state.all_comments)
      .filter((key) => {
        const activity_id = active_schedule.value.activities[key].id;
        const activity = $g.value.getTask(activity_id);
        const assignees = activity?.resources?.map?.((resource_uid) => {
          return active_schedule.value.resources.find(resource => resource.uid === resource_uid)?.external_id;
        })?.filter?.(Boolean);
        return assignees?.includes?.(auth_store?.logged_in_user_details?.user_id);
      })
      .reduce((acc, key) => {
        acc[key] = state.all_comments[key];
        return acc;
      }, {});
  }
});

watch(() => state.date_range, async () => {
  state.is_loading = true;
  const { data } = await $services.tasks.post({
    url: 'tasks/comments',
    query: query_params.value,
  });
  state.filtered_comments = data.data;
  state.is_loading = false;
});
</script>

<template>
  <Teleport :to="is_fullscreen ? '#pm-fullscreen-container' : 'body'">
    <Transition name="sidebar-slide">
      <div v-if="active" class="slide-over fixed bg-white right-0 inset-y-0 z-[1000] w-[30vw]">
        <div class="relative flex flex-col flex-1 h-full bg-white shadow-xl">
          <div class="flex items-center justify-between mx-6 my-4">
            <div class="font-semibold text-gray-900 text-md">
              {{ $t('Comments') }}
            </div>
            <div class="ml-auto">
              <HawkButton icon type="text" class="!h-10 !w-10 ml-1" @click="emit('close')">
                <IconHawkXClose class="text-gray-500" />
              </HawkButton>
            </div>
          </div>
          <hr class="border-gray-300">
          <div class="px-6 py-4 scrollbar">
            <div class="flex justify-between mb-3 -mx-3">
              <HawkMenu
                class="!border-0"
                :items="left_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton type="text" size="xs">
                    {{ left_menu_items.find((menu_item) => menu_item.uid === state.left_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
              <HawkMenu
                class="!border-0"
                :items="right_menu_items"
                additional_trigger_classes="!ring-0"
                additional_dropdown_classes="w-[200px]"
                position="fixed"
              >
                <template #trigger="{ open }">
                  <HawkButton
                    v-tippy="state.right_menu_selection === 'custom' ? `${$date(state.date_range[0], 'DD MMMM YYYY')} ${$t('to')} ${$date(state.date_range[1], 'DD MMMM YYYY')}` : ''"
                    type="text"
                    size="xs"
                  >
                    {{ right_menu_items.find((menu_item) => menu_item.uid === state.right_menu_selection).label }}
                    <IconHawkChevronUp v-if="open" />
                    <IconHawkChevronDown v-else />
                  </HawkButton>
                </template>
              </HawkMenu>
            </div>
            <HawkLoader v-if="state.is_loading" />
            <HawkIllustrations
              v-else-if="!Object.keys(state.filtered_comments)?.length"
              type="no-data"
              for="comments"
            />
            <template v-else>
              <div v-for="(comments, activity_uid, i) in state.filtered_comments" :key="activity_uid" class="">
                <div class="mb-2 text-sm font-semibold text-gray-400 cursor-pointer hover:text-gray-600 hover:underline hover:underline-offset-4" @click="onClick(activity_uid)">
                  #{{ getActivityWBSCode(activity_uid) }} • {{ getActivityText(activity_uid) }}
                </div>
                <div class="flex">
                  <div class="flex items-center h-8 relative">
                    <div v-if="isUnread(activity_uid) === 'unread_mentions'" class="!w-2 !h-2 bg-error-500 rounded-lg absolute -left-4" />
                    <div v-else-if="isUnread(activity_uid) === 'unread_comments'" class="!w-2 !h-2 bg-gray-300 rounded-lg absolute -left-4" />
                  </div>
                  <HawkMembers
                    :members="comments[0].owner.uid"
                    :has_avatar="true"
                    size="sm"
                    class="flex !items-start mr-3"
                  />
                  <div class="flex flex-col w-full">
                    <div class="flex items-center justify-between">
                      <HawkMembers
                        :members="comments[0].owner.uid"
                        type="label"
                        :has_avatar="false"
                        name_classes="text-gray-900 text-sm font-semibold"
                      />
                      <div class="text-xs text-gray-600 font-regular">
                        {{ $date(comments[0].createdAt, 'DD MMMM YYYY hh:mma') }}
                      </div>
                    </div>
                    <div class="text-sm text-gray-700 font-regular">
                      {{ DOMPurify.sanitize(comments[0].text, { ALLOWED_TAGS: [] }) }}
                    </div>
                    <div class="flex mt-1 gap-x-2">
                      <div v-for="(item, index) in state.grouped_attachments[comments[0].uid]?.slice?.(0, 3)" :key="index">
                        <img
                          v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
                          class="object-cover rounded-lg w-[64px] h-[64px]"
                          alt="thumbnail"
                        >
                        <div
                          v-else
                          class="flex items-center justify-center bg-gray-100 rounded-lg w-[64px] h-[64px]"
                        >
                          <HawkFileIcon :file_name="item.file_name" />
                        </div>
                      </div>
                      <div v-if="state.grouped_attachments[comments[0].uid]?.length > 3" class="flex items-center text-sm text-gray-700 font-regular">
                        +{{ state.grouped_attachments[comments[0].uid]?.length - 3 }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="comments.length - 1 > 0"
                  class="ml-11 flex items-center gap-1 pt-2 text-sm font-semibold cursor-pointer text-primary-700 w-fit"
                  @click="onClick(activity_uid)"
                >
                  {{ comments.length - 1 }} {{ $t('more') }}
                  <IconHawkChevronRight class="inline" />
                </div>
                <hr v-if="i < Object.keys(state.filtered_comments)?.length - 1" class="my-4 -mx-6 border-b-2 border-gray-100">
              </div>
            </template>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.sidebar-slide-enter-active,
.sidebar-slide-leave-active {
  transition: transform 0.5s ease;
}

.sidebar-slide-enter-from,
.sidebar-slide-leave-to {
  transform: translateX(100%);
}
</style>
