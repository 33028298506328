<script setup>
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import EmailPopup from '~/forms/components/form-workflows/sidebar/email-block/email-popup.vue';
import FormWorkflowBlockReminders from '~/forms/components/form-workflows/sidebar/form-workflow-block-reminders.vue';

const props = defineProps({
  transmittal: {
    type: Object,
  },
});

const emit = defineEmits(['close']);

const state = reactive({
  is_loading: false,
  custom_email: {},
  fetching_data: false,
  reminder: null,
});

const form$ = ref(null);
const { $t, $toast, $services } = useCommonImports();

const email_popup = useModal({
  component: EmailPopup,
  attrs: {
    onClose() {
      email_popup.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    content: $t('This will clear the custom email, are you sure?'),
    onClose() {
      delete_popup.close();
    },
  },
});

function openDeletePopup(index) {
  delete_popup.patchOptions({
    attrs: {
      confirm: async () => {
        delete state.custom_email[index];
        email_popup.close();
        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}

function openEmailPopup(index) {
  email_popup.patchOptions({
    attrs: {
      popup_title: $t('Custom Email'),
      is_popup: true,
      has_dynamic_fields: false,
      form: state.custom_email[index],
      onResetForm() {
        openDeletePopup(index);
      },
      onSave(val) {
        state.custom_email[index] = val;
        email_popup.close();
      },
    },
  });
  email_popup.open();
}

async function submitHandler() {
  try {
    state.is_loading = false;
    const reminders = {
      resourceRef: props.transmittal.uid,
      ...form$.value.data,
      type: 'reminder',
      asset: props.transmittal.asset,
    };

    reminders.config.forEach((config, index) => {
      config.customEmail = state.custom_email[index];
    });

    if (state.reminder) {
      await $services.dms.patch({
        id: `reminders/${state.reminder.uid}`,
        body: {
          reminders,
        },
      });
    }
    else {
      await $services.dms.post({
        id: 'reminders',
        body: {
          reminders,
        },
      });
    }
    $toast({
      title: 'Success',
      text: 'Reminders updated successfully',
      type: 'success',
    });
    emit('close');
  }
  catch (err) {
    logger.error(err);
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
  finally {
    state.is_loading = false;
  }
}

const has_workflow = props.transmittal?.workflow_template;

const recipients = has_workflow
  ? [{ label: 'Submitters', value: 'submitters' }]
  : [
      { label: 'Submitters', value: 'submitters' },
      { label: 'Reviewers', value: 'reviewers' },
      { label: 'Approvers', value: 'approvers' },

    ];

async function getData() {
  try {
    state.fetching_data = true;
    const { data } = await $services.dms.get({
      id: 'reminders',
      query: {
        resourceUid: props.transmittal.uid,
        asset: props.transmittal.asset,
      },
    });
    state.fetching_data = false;
    const reminder = data.reminders[0];
    if (reminder) {
      form$.value.load({ config: reminder.config });
      state.custom_email = Object.assign({}, reminder.config.map(config => config.customEmail));
      state.reminder = reminder;
    }
  }
  catch (err) {
    logger.error(err);
    state.fetching_data = false;
  }
}

onMounted(getData);
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]">
    <Vueform
      ref="form$"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      size="sm"
      :endpoint="submitHandler"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #icon>
            <IconHawkBellRingingOne />
          </template>
          <template #title>
            {{ $t('Reminder') }}
          </template>
          <template #subtitle>
            {{ $t('Send email/push notifications based on the configured rules. Use Assign/Notify to escalate and assign to other members.') }}
          </template>
        </hawk-modal-header>
        <hawk-loader v-if="state.fetching_data" container_class="m-5" />
        <hawk-modal-content v-show="!state.fetching_data">
          <ListElement
            name="config"
            add-text="+ Add reminder"
            :presets="['repeatable_list']"
            rules="required"
            min="1"
            :override-classes="{
              ListElement: {
                listItem: 'flex w-full bg-gray-50 p-4',
                remove: ['h-5 w-5 ml-auto'],
              },
            }"
          >
            <template #default="{ index }">
              <ObjectElement :embed="true" :name="index">
                <CheckboxgroupElement
                  class="mb-3"
                  name="to"
                  rules="required"
                  :label="$t('Recipients')"
                  :items="recipients"
                />
                <HiddenElement name="uid" :meta="true" />
                <RadiogroupElement
                  name="is_once"
                  :label="$t('Frequency')"
                  class="mb-2"
                  :default="false"
                  :remove-class="{
                    wrapper: 'flex-col',
                  }"
                  :add-classes="{
                    RadiogroupRadio: { container: 'mr-4' },
                  }"
                  :items="[{
                             value: true,
                             label: $t('Once'),
                           },
                           {
                             value: false,
                             label: $t('Multiple times'),
                           }]"
                />
                <FormWorkflowBlockReminders :form-data="{ reminders: form$?.data?.config?.[index] }" :payload-data="{}" />
                <StaticElement :label="$t('Custom Email')" class="mt-6">
                  <HawkButton class="mr-4 -mt-4" type="link" @click="openEmailPopup(index)">
                    {{ state.custom_email?.[index]?.subject ? $t('Edit') : $t('Add') }}
                  </HawkButton>
                </StaticElement>
              </ObjectElement>
            </template>
          </ListElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits size="sm"
                  name="submit"
                  :button-label="$t('Save')"
                  :loading="state.is_loading"
                  button-class="vf-btn-primary"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
