<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { $g, active_schedule, is_fullscreen } = storeToRefs(project_management_store);
const { create_tracking } = project_management_store;
const { get_user_or_team_name, is_type_user } = common_store;

// TODO: Cleanup
const timeline_options = [
  ['Today', 'today', dayjs().utc().startOf('day').toISOString(), dayjs().utc().endOf('day').toISOString()],
  ['Yesterday', 'yesterday', dayjs().utc().subtract(1, 'day').startOf('day').toISOString(), dayjs().utc().subtract(1, 'day').endOf('day').toISOString()],
  ['This week', 'this_week', dayjs().utc().startOf('day').toISOString(), dayjs().utc().add(6, 'day').endOf('day').toISOString()],
  ['Last week', 'last_week', dayjs().utc().subtract(1, 'week').startOf('day').toISOString(), dayjs().utc().subtract(1, 'day').endOf('day').toISOString()],
  ['Custom', 'custom', null, null],
].map(item => ({
  name: item[0],
  label: $t(item[0]),
  value: item[1],
  range: [item[2], item[3]],
}));

const state = reactive({
  form_data: {
    timeline: 'today',
  },
  resource_items: [],
  timeline: [dayjs().utc().startOf('day').toISOString(), dayjs().utc().endOf('day').toISOString()],
});

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      date_range_modal.close();
    },
    onOkay(range) {
      state.timeline = [
        dayjs(range[0]).utc().startOf('day').toISOString(),
        dayjs(range[1]).utc().endOf('day').toISOString(),
      ];
      date_range_modal.close();
    },
  },
});

const selected_resource = computed(() => {
  return state.resource_items.find(item => item.value === state.form_data.resource);
});

function setTimeline(type) {
  if (type !== 'custom') {
    const item = timeline_options.find(i => i.value === type);
    state.timeline = [...item?.range ?? [null, null]];
  }
  else {
    date_range_modal.open();
  }
}

async function onSave() {
  try {
    if (state.form_data.cost)
      state.form_data.cost = Number.parseFloat(state.form_data.cost.replaceAll(',', ''));
    const payload = {
      start: state.timeline[0],
      end: state.timeline[1],
      ...state.form_data,
    };
    await create_tracking(payload, props.activity.uid);
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: error?.data?.title || $t('Something went wrong'),
      text: error?.data?.description || $t('Please try again'),
      type: 'error',
    });
  }
  finally {
    emit('close');
  }
}

onMounted(() => {
  state.resource_items = active_schedule.value.resources.filter((resource) => {
    return active_schedule.value.resource_assignments.filter(item => item.activity === props.activity.uid).some(assignment =>
      assignment.resource === resource.uid,
    );
  });
  state.resource_items = state.resource_items.map((item) => {
    let label;
    if (item.type === 'member')
      label = get_user_or_team_name(item.external_id);
    else label = item.name;
    return {
      ...item,
      value: item.uid,
      label,
    };
  });
});
</script>

<template>
  <HawkModalContainer
    id="activity-tracking-modal-container"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
  >
    <Vueform
      v-model="state.form_data"
      size="sm"
      sync
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: {
          container: 12,
          label: 3,
          wrapper: 9,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              {{ $t('Activity tracking') }}
            </div>
            <div class="text-sm font-normal text-gray-600">
              {{ $g.getWBSCode($g.getTask(props.activity.id)) }} {{ $g.getTask(props.activity.id).name }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="text-sm font-normal text-gray-900 mb-3">
            {{ $t('You can track different types of cost associated and work done by resources') }}
          </div>
          <SelectElement
            name="timeline"
            :label="$t('Timeline')"
            :native="false"
            :items="timeline_options"
            :can-clear="false"
            :can-deselect="false"
            append-to="#activity-tracking-modal-container"
            class="mb-6"
            @select="setTimeline"
          >
            <template #after>
              <div class="text-sm font-normal text-gray-600 mt-1">
                {{ $t('Choose the date/range for which the values are recorded') }}.
              </div>
            </template>
          </SelectElement>
          <SelectElement
            name="resource"
            :label="$t('Resource')"
            :placeholder="$t('Choose')"
            :native="false"
            :items="state.resource_items"
            append-to="#activity-tracking-modal-container"
            class="mb-6"
          >
            <template #single-label="{ value }">
              <div class="w-full p-1">
                <template v-if="value.type === 'custom'">
                  <div class="flex items-center font-medium text-gray-700">
                    <div class="w-6 h-6 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full mr-1.5">
                      {{ value.name.charAt(0) }}
                    </div>
                    {{ value.name }}
                  </div>
                </template>
                <template v-else-if="value.type === 'member'">
                  <template v-if="is_type_user(value.external_id)">
                    <HawkMembers :members="value.external_id" :has_avatar="true" type="label" size="xs" />
                  </template>
                  <template v-else>
                    <HawkMembers :members="value.external_id" :has_avatar="true" type="label" size="xs" />
                  </template>
                </template>
              </div>
            </template>
            <template #option="{ option }">
              <template v-if="option.type === 'custom'">
                <div class="w-6 h-6 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full mr-1.5">
                  {{ option.name.charAt(0) }}
                </div>
                {{ option.name }}
              </template>
              <template v-else-if="option.type === 'member'">
                <template v-if="is_type_user(option.external_id)">
                  <HawkMembers :members="option.external_id" :has_avatar="true" type="label" size="xs" />
                </template>
                <template v-else>
                  <HawkMembers :members="option.external_id" :has_avatar="true" type="label" size="xs" />
                </template>
              </template>
            </template>
          </SelectElement>
          <TextElement
            v-if="selected_resource && selected_resource.cost_type !== 'fixed'"
            name="units"
            :label="selected_resource.cost_type === 'per_hour' ? $t('Duration') : $t('Items')"
            input-type="number"
            class="mb-6"
          >
            <template #addon-after>
              <template v-if="selected_resource.cost_type === 'per_hour'">
                {{ $t('hrs') }}
              </template>
              <template v-else-if="selected_resource.cost_type === 'per_item'">
                {{ $t('pcs') }}
              </template>
            </template>
          </TextElement>
          <TextElement
            v-if="active_schedule.track_costs"
            name="cost"
            :label="$t('Amount spent')"
            :mask="{
              mask: 'number',
              thousandsSeparator: ',',
              scale: 2,
              padFractionalZeros: false,
              normalizeZeros: false,
              radix: '.',
              mapToRadix: ['.'],
              min: 0,
              autofix: false,
            }"
            class="mb-6"
          >
            <template #addon-before>
              {{ active_schedule?.currency?.symbol }}
            </template>
            <template #after>
              <div v-if="selected_resource && state.form_data?.units" class="text-sm font-normal text-gray-600 mt-1">
                {{ $t('Estimated cost') }} -
                ${{ state.form_data?.units * selected_resource?.cost }}
                <template v-if="selected_resource?.cost_type === 'per_hour'">
                  (${{ selected_resource?.cost }} {{ $t('per hour') }})
                </template>
                <template v-else-if="selected_resource?.cost_type === 'per_item'">
                  (${{ selected_resource?.cost }} {{ $t('per item') }})
                </template>
              </div>
            </template>
          </TextElement>
          <TextElement
            v-if="selected_resource?.cost_type === 'per_hour'"
            name="work"
            :label="$t('Work done')"
            input-type="number"
            class="mb-6"
          >
            <template #addon-after>
              {{ $t('units') }}
            </template>
          </TextElement>
          <div class="border border-gray-300 bg-gray-25 flex items-center gap-3 rounded-lg p-4">
            <IconHawkInfoCircle class="text-gray-600" />
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Not all fields are mandatory. You can select few fields based on scenario.') }}
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
