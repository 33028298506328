<script setup>
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { isEqual, isNil, uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: [String, Number, Boolean],
    default: '',
  },
});

const project_management_store = useProjectManagementStore();
const { $g, active_schedule, active_task, active_task_uid, is_mini_loading, is_schedule_editable } = storeToRefs(project_management_store);
const { update_activity, add_or_update_custom_field_value, clear_custom_field_value, set_schedule_dirtiness } = project_management_store;

const form$ = ref(null);

const state = reactive({
  is_editor_active: false,
  is_dropdown_open: false,
  hovered_option: '',
  form_data: {
    value: props.value,
  },
});

const text_field_options = computed(() => uniq(active_schedule.value?.custom_fields?.[props.name]?.values));

const filtered_text_field_options = computed(() => text_field_options.value.filter(option => option.toLowerCase().includes(state.form_data.value.toLowerCase()) && option !== state.form_data.value));

async function activateEditor() {
  state.is_editor_active = true;
  await nextTick();
  form$.value.elements$.value.input.focus();
  state.is_dropdown_open = true;
}

function toggleDropdown() {
  if (filtered_text_field_options.value.length)
    state.is_dropdown_open = !state.is_dropdown_open;
}

function onBlur() {
  setTimeout(() => {
    state.is_dropdown_open = false;
  }, 100);
}

async function activateDateElement() {
  state.is_editor_active = true;
  await nextTick();
  form$.value.elements$.value.vue_date_picker_ref.openMenu();
}

function deactivateEditor(save = false) {
  state.is_editor_active = false;
  if (save)
    handleCustomFieldSave();
  else
    state.form_data.value = props.value;
}

async function updateCustomField(type, task_uid, field_name, field_value) {
  if (type === 'add_or_update') {
    await add_or_update_custom_field_value(
      {
        uids: task_uid,
        custom_fields: [
          {
            field: field_name,
            value: field_value,
          },
        ],
      },
    );
  }
  else if (type === 'clear') {
    await clear_custom_field_value(
      {
        uids: [task_uid],
        fields: [field_name],
      },
    );
  }
}

function handleCustomFieldSave() {
  try {
    is_mini_loading.value = true;
    const trimmed_field_name = props.name;

    let field_value = DOMPurify.sanitize(state.form_data.value || '', { ALLOWED_TAGS: [] });
    if (
      state.form_data.value
      && ['number', 'money']
        .includes(active_schedule.value.custom_fields[trimmed_field_name].type)
    ) {
      field_value = Number.parseFloat(state.form_data.value);
    }

    const new_value = {
      ...active_schedule.value.activities[active_task_uid.value].custom_field_values,
      [trimmed_field_name]: field_value,
    };

    update_activity({
      uid: active_task_uid.value,
      custom_field_values: new_value,
      [`custom_field_${props.name}`]: field_value,
    }, false, ['custom_field_values', `custom_field_${props.name}`]);
    state.form_data.value = field_value;

    if (!isEqual(state.form_data.value, props.value)) {
      if (!is_schedule_editable.value) {
        if (field_value)
          updateCustomField('add_or_update', active_task_uid.value, trimmed_field_name, field_value);
        else if (props.value)
          updateCustomField('clear', active_task_uid.value, trimmed_field_name, field_value);
      }
      else {
        set_schedule_dirtiness();
        $g.value.refreshTask(active_task.value.id);
      }
    }
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    is_mini_loading.value = false;
  }
}

function handleKeydown(event) {
  if (!['Enter', 'Escape'].includes(event.key))
    return;
  event.preventDefault();
  deactivateEditor(event.key === 'Enter');
}

function handleKeydownTextElement(event) {
  if (!['Enter', 'Escape', 'ArrowDown', 'ArrowUp'].includes(event.key))
    return;
  event.preventDefault();

  switch (event.key) {
    case 'Enter':
      handleEnterKey();
      break;
    case 'ArrowDown':
      handleArrowDownKey();
      break;
    case 'ArrowUp':
      handleArrowUpKey();
      break;
    case 'Escape':
      deactivateEditor(false);
      break;
  }
}

function handleEnterKey() {
  if (state.hovered_option !== '' && state.is_dropdown_open) {
    state.form_data.value = state.hovered_option;
    state.is_dropdown_open = false;
  }
  else {
    deactivateEditor(true);
  }
}

function handleArrowDownKey() {
  if (state.hovered_option === '') {
    state.hovered_option = filtered_text_field_options.value[0];
  }
  else {
    const index = filtered_text_field_options.value.indexOf(state.hovered_option);
    if (index < filtered_text_field_options.value.length - 1)
      state.hovered_option = filtered_text_field_options.value[index + 1];
  }
  document.getElementById(state.hovered_option)?.scrollIntoView?.({ block: 'nearest' });
}

function handleArrowUpKey() {
  if (state.hovered_option === '') {
    state.hovered_option = filtered_text_field_options.value[filtered_text_field_options.value.length - 1];
  }
  else {
    const index = filtered_text_field_options.value.indexOf(state.hovered_option);
    if (index > 0)
      state.hovered_option = filtered_text_field_options.value[index - 1];
  }
  document.getElementById(state.hovered_option)?.scrollIntoView?.({ block: 'nearest' });
}

function handleDateClear(selected_date) {
  if (props.value && dayjs(props.value).isSame(selected_date, 'day'))
    state.form_data.value = null;
}
</script>

<template>
  <Vueform
    ref="form$"
    v-model="state.form_data"
    sync
    size="sm"
  >
    <div class="col-span-12">
      <template v-if="type === 'date'">
        <DateTimeElement
          v-show="state.is_editor_active"
          name="value"
          :options="{
            clearable: false,
            format: 'dd MMMM yyyy',
            teleport: false,
            hideInputIcon: true,
            placeholder: '&ndash;',
          }"
          class="date_time date-time-underline"
          @update:model-value="handleDateClear"
        />
        <div v-show="!state.is_editor_active" class="h-[34px] flex items-center">
          {{ value ? $date(value, 'DD MMMM YYYY') : '&ndash;' }}
        </div>
        <!-- NOTE: The below left values need to be changed if at any time the width of the modal is changed (MODAL WIDTH CHANGED) -->
        <div
          class="relative w-fit -top-[26px] h-0 text-sm font-medium text-primary-600 hover:underline cursor-pointer"
          :class="{
            'left-[194px]': state.is_editor_active,
            'left-[235px]': !state.is_editor_active,
          }"
        >
          <div v-if="state.is_editor_active" class="flex gap-3">
            <IconHawkXClose
              class="w-4 h-4 text-gray-600 cursor-pointer"
              @click="deactivateEditor(false)"
            />
            <IconHawkCheck
              class="w-4 h-4 text-primary-600 cursor-pointer"
              @click="deactivateEditor(true)"
            />
          </div>
          <IconHawkPencilOne
            v-else-if="[$g.config.types.task, $g.config.types.milestone].includes(active_task.type)"
            class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
            @click="activateDateElement"
          />
        </div>
      </template>
      <template v-else-if="type === 'text'">
        <TextElement
          v-show="state.is_editor_active"
          name="value"
          :presets="['focused_underline', 'xs_variant']"
          :add-classes="{
            TextElement: {
              input: '!text-xs !font-medium !text-gray-900',
              inputContainer: '-ml-[9px] !bg-transparent',
            },
          }"
          class="h-9"
          @keydown="handleKeydownTextElement"
          @blur="onBlur"
          @click="toggleDropdown"
          @input="state.is_dropdown_open = true"
        >
          <template #addon-after>
            <div class="flex gap-3">
              <IconHawkXClose
                class="w-4 h-4 text-gray-600 cursor-pointer"
                @click="deactivateEditor(false)"
              />
              <IconHawkCheck
                class="w-4 h-4 text-primary-600 cursor-pointer"
                @click="deactivateEditor(true)"
              />
            </div>
          </template>
          <template #after>
            <div
              v-if="state.is_dropdown_open && filtered_text_field_options.length"
              class="dropdown relative z-[1003] -left-2 bg-white border border-gray-300 rounded-lg max-h-[250px] scrollbar"
            >
              <template
                v-for="option in filtered_text_field_options"
                :key="option"
              >
                <div
                  :id="option"
                  class="text-xs font-medium text-gray-600 cursor-pointer hover:text-gray-900 hover:bg-gray-50 rounded-lg p-2"
                  :class="{
                    'bg-gray-50 text-gray-900': state.hovered_option === option,
                  }"
                  @click="state.form_data.value = option"
                >
                  {{ option }}
                </div>
              </template>
            </div>
          </template>
        </TextElement>
        <div
          v-show="!state.is_editor_active"
          class="my-[10px] flex items-center gap-0.5"
        >
          <template v-if="value">
            <div class="w-full flex justify-between">
              <div class="flex gap-0.5">
                <div v-if="type === 'money'" class="">
                  {{ active_schedule.currency.symbol }}
                </div>
                {{ value }}
              </div>
              <IconHawkPencilOne
                v-if="[$g.config.types.task, $g.config.types.milestone].includes(active_task.type)"
                class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
                @click="activateEditor"
              />
            </div>
          </template>
          <template v-else>
            <div class="w-full flex justify-between">
              <div>
                &ndash;
              </div>
              <IconHawkPencilOne
                v-if="[$g.config.types.task, $g.config.types.milestone].includes(active_task.type)"
                class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
                @click="activateEditor"
              />
            </div>
          </template>
        </div>
      </template>
      <template v-if="['number', 'money'].includes(type)">
        <TextElement
          v-show="state.is_editor_active"
          name="value"
          input-type="number"
          :presets="['focused_underline', 'xs_variant']"
          :add-classes="{
            TextElement: {
              input: type === 'money' ? '!pl-0.5 !text-xs !font-medium !text-gray-900' : ' !text-xs !font-medium !text-gray-900',
              inputContainer: '-ml-[9px] !bg-transparent',
            },
          }"
          class="h-9"
          @keydown="handleKeydown"
        >
          <template v-if="type === 'money'" #addon-before>
            <div class="mt-0.5 text-xs font-medium text-gray-900">
              {{ active_schedule.currency.symbol }}
            </div>
          </template>
          <template #addon-after>
            <div class="flex gap-3">
              <IconHawkXClose
                class="w-4 h-4 text-gray-600 cursor-pointer"
                @click="deactivateEditor(false)"
              />
              <IconHawkCheck
                class="w-4 h-4 text-primary-600 cursor-pointer"
                @click="deactivateEditor(true)"
              />
            </div>
          </template>
        </TextElement>
        <div
          v-show="!state.is_editor_active"
          class="my-[10px] flex items-center gap-0.5"
        >
          <template v-if="!(isNil(value) || value === '')">
            <div class="w-full flex justify-between">
              <div class="flex gap-0.5">
                <div v-if="type === 'money'" class="">
                  {{ active_schedule.currency.symbol }}
                </div>
                {{ value }}
              </div>
              <IconHawkPencilOne
                v-if="[$g.config.types.task, $g.config.types.milestone].includes(active_task.type)"
                class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
                @click="activateEditor"
              />
            </div>
          </template>
          <template v-else>
            <div class="w-full flex justify-between">
              <div>
                &ndash;
              </div>
              <IconHawkPencilOne
                v-if="[$g.config.types.task, $g.config.types.milestone].includes(active_task.type)"
                class="w-4 h-4 text-gray-600 cursor-pointer group-hover:visible invisible mr-1"
                @click="activateEditor"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </Vueform>
</template>

<style scoped lang="scss">
.date_time {
  :deep(.dp__main) {
    @apply -ml-2;
  }

  :deep(.dp__input) {
    background-color: transparent;
    margin-top: -1px;
    @apply px-2;
    @apply py-2;
    @apply text-xs;
    @apply font-medium;
    @apply text-gray-900;
  }

  :deep(.dp__input_focus){
    @apply bg-transparent;
  }

  :deep(.dp__disabled) {
    @apply text-gray-900 cursor-text;
  }

  :deep(.dp__input::placeholder) {
    @apply text-black text-xs font-medium;
  }
}
</style>
