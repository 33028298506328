<script setup>
import { compact } from 'lodash-es';

const props = defineProps({
  type: {
    type: String,
    default: 'default',
    validator(value) {
      return ['default', 'light', 'outlined', 'text', 'link', 'plain', 'dark'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'success', 'error', 'gray', 'active', 'warning'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  icon: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

function getDefaultTypeClasses(color) {
  const classes = {
    primary: props.disabled ? ['text-white bg-primary-200'] : ['!shadow-none text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100'],
    success: props.disabled ? ['text-white bg-green-200'] : ['!shadow-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-100'],
    error: props.disabled ? ['text-white bg-red-200'] : ['!shadow-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-100'],
    gray: props.disabled ? ['text-gray-700 bg-gray-100'] : ['!shadow-none text-gray-700 bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:ring-gray-100'],
    warning: props.disabled ? ['text-white bg-warning-200'] : ['!shadow-none text-white bg-warning-600 hover:bg-warning-700 focus:ring-4 focus:ring-warning-100'],
  };
  return classes[color || 'primary'];
}

function getLightTypeClasses(color) {
  const classes = {
    primary: props.disabled ? ['text-primary-300 bg-primary-50 !shadow-none'] : ['text-primary-700 bg-primary-50 hover:bg-primary-100 hover:text-primary-800 focus:ring-4 focus:ring-primary-100 !shadow-none'],
    success: [''],
    error: props.disabled ? ['text-red-300 bg-red-50 !shadow-none'] : ['text-red-700 bg-red-50 hover:bg-red-100 hover:text-red-800 focus:ring-4 focus:ring-red-100 !shadow-none'],
    gray: props.disabled ? ['text-gray-300 bg-gray-50'] : ['text-gray-700 bg-gray-50 hover:bg-gray-100 hover:text-gray-800 focus:ring-4 focus:ring-gray-100'],
    warning: props.disabled ? ['text-warning-300 bg-warning-50 !shadow-none'] : ['text-warning-700 bg-warning-50 hover:bg-warning-100 hover:text-warning-800 focus:ring-4 focus:ring-warning-100 !shadow-none'],
  };
  return classes[color || 'primary'];
}

function getOutlinedTypeClasses(color) {
  const classes = {
    primary: props.disabled ? ['!border-gray-200 text-gray-300 bg-white'] : ['!border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 focus:ring-4 focus:ring-gray-100'],
    success: props.disabled ? [''] : ['!border-success-600 text-success-700 bg-white hover:bg-success-50 hover:text-success-800 focus:ring-4 focus:ring-success-100'],
    error: props.disabled ? [''] : ['!border-error-600 text-error-700 bg-white hover:bg-error-50 hover:text-error-800 focus:ring-4 focus:ring-error-100'],
    gray: props.disabled ? ['!border-gray-200 text-gray-300 bg-white'] : ['!border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 focus:ring-4 focus:ring-gray-100'],
    active: props.disabled ? ['!border-primary-200 text-primary-300 bg-white'] : ['!border-primary-600 text-primary-700 !bg-primary-50 hover:text-primary-800 focus:ring-4 focus:ring-primary-100'],
    warning: [''],
  };
  return classes[color || 'primary'];
}

function getButtonClasses() {
  const default_classes = {
    default: {
      primary: getDefaultTypeClasses('primary'),
      success: getDefaultTypeClasses('success'),
      error: getDefaultTypeClasses('error'),
      gray: getDefaultTypeClasses('gray'),
      warning: getDefaultTypeClasses('warning'),
    },
    light: {
      primary: getLightTypeClasses('primary'),
      success: getLightTypeClasses('success'),
      error: getLightTypeClasses('error'),
      gray: getLightTypeClasses('gray'),
      warning: getLightTypeClasses('warning'),
    },
    outlined: {
      primary: getOutlinedTypeClasses('primary'),
      success: getOutlinedTypeClasses('success'),
      error: getOutlinedTypeClasses('error'),
      gray: getOutlinedTypeClasses('gray'),
      active: getOutlinedTypeClasses('active'),
      warning: getOutlinedTypeClasses('warning'),
    },
    text: {
      primary: props.disabled ? ['text-gray-300 !shadow-none'] : ['text-gray-600 hover:text-gray-700 !shadow-none hover:bg-gray-50'],
      success: [''],
      error: [''],
      gray: [''],
      warning: [''],
    },
    plain: {
      primary: props.disabled ? ['!shadow-none text-gray-300'] : ['!shadow-none text-gray-700'],
      success: [''],
      error: props.disabled ? ['!shadow-none text-red-300'] : ['!shadow-none text-red-700 hover:bg-red-50'],
      gray: props.disabled ? ['!shadow-none text-gray-300'] : ['!shadow-none text-gray-700'],
      warning: props.disabled ? ['!shadow-none text-warning-300'] : ['!shadow-none text-warning-700'],
    },
    dark: {
      primary: props.disabled ? [''] : ['!shadow-none text-white bg-gray-700 hover:bg-gray-800 hover:text-white focus:ring-2 focus:ring-gray-300'],
      success: [''],
      error: [''],
      gray: [''],
      warning: [''],
    },
    link: {
      primary: props.disabled ? ['!p-0 !shadow-none text-primary-200'] : ['!p-0 !shadow-none text-primary-700 hover:text-primary-800'],
      success: [''],
      error: props.disabled ? ['!p-0 !shadow-none text-red-200'] : ['!p-0 !shadow-none text-red-700 hover:text-red-800'],
      gray: props.disabled ? ['!p-0 !shadow-none text-gray-200'] : ['!p-0 !shadow-none gray-600 hover:gray-800'],
      warning: [''],
    },
  };

  return default_classes?.[props.type || 'default']?.[props.color || 'primary'];
}

function getSizeClasses() {
  const size_classes = {
    'xxs': props.icon ? ['justify-center w-[24px] h-[24px]'] : ['px-2 py-2 text-sm h-[24px]'],
    'xs': props.icon ? ['justify-center w-[28px] h-[28px]'] : ['px-3 py-2 text-sm h-[28px]'],
    'sm': props.icon ? ['justify-center w-9 h-9'] : ['px-3 py-2 text-sm h-9'],
    'md': props.icon ? ['justify-center w-10 h-10'] : ['px-4 py-2 text-sm h-10'],
    'lg': props.icon ? ['justify-center w-11 h-11'] : ['px-4 py-2 text-base h-11'],
    'xl': props.icon ? ['justify-center w-12 h-12'] : ['px-6 py-3 text-base h-12'],
    '2xl': props.icon ? ['justify-center w-14 h-14'] : ['px-6 py-3 text-lg h-14'],
  };
  return size_classes[props.size];
}

const button_classes = computed(() => {
  const classes = [];

  const defaultBtn = ['inline-flex items-center border border-transparent font-medium shadow-sm relative hawk-button rounded-lg focus:outline-none'];

  classes.push(...defaultBtn, ...getButtonClasses(), ...getSizeClasses());

  props.rounded && classes.push('!rounded-full');
  props.block && classes.push('!block w-full');
  props.icon && classes.push('icon');

  return compact(classes);
});
</script>

<template>
  <button
    type="button"
    :class="button_classes"
    :disabled="disabled || loading"
    @click="emit('click', $event)"
  >
    <div
      v-if="loading"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <path
          fill="currentColor"
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        >
          <animateTransform
            attributeName="transform"
            dur="0.75s"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 12;360 12 12"
          />
        </path>
      </svg>
    </div>
    <div class="flex items-center justify-center gap-2" :class="{ invisible: loading }">
      <slot />
    </div>
  </button>
</template>

<style lang="scss">
.hawk-button {
  svg {
    color: inherit;
  }
}
</style>
