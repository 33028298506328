<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import PmActivateVersionModal from './pm-activate-version-modal.vue';

const emit = defineEmits(['close']);

const $t = inject('$t');
const $date = inject('$date');

const route = useRoute();
const project_management_store = useProjectManagementStore();

const { is_refresh_required } = storeToRefs(project_management_store);
const { set_versions, create_version, download_version, update_version, delete_version } = project_management_store;

const state = reactive({
  versions: [],
  is_versions_loading: false,
});

const form$ = ref(null);

const activate_version_modal = useModal({
  component: PmActivateVersionModal,
  attrs: {
    onClose() {
      activate_version_modal.close();
    },
  },
});

onMounted(() => {
  state.is_versions_loading = true;
  set_versions()
    .then((response) => {
      state.versions = response.data;
      state.is_versions_loading = false;
    })
    .catch(err => logger.error(err));
});

async function onDownloadVersion(version) {
  const { data } = await download_version({
    version_id: version.uid,
  });
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  anchor.href = data.url;
  anchor.download = 'file';
  anchor.click();
}

async function onActivateVersion(version) {
  activate_version_modal.patchOptions({
    attrs: {
      async onConfirm(override_actuals) {
        activate_version_modal.close();
        state.is_versions_loading = true;
        await update_version({
          version_id: version.uid,
          body: {
            active: true,
            override_actuals,
          },
        });
        is_refresh_required.value = true;
        state.is_versions_loading = false;
        emit('close');
      },
    },
  });
  activate_version_modal.open();
}

async function onDeleteVersion(version) {
  await delete_version({
    version_id: version.uid,
  });
  state.versions = state.versions.filter(ver => ver.uid !== version.uid);
}

async function onFormUpdated(event) {
  if (event.type !== 'upload_completed')
    return;

  const file_data = form$.value.data.file;
  const now = new Date().toISOString();
  const service_object = {
    fileName: file_data.name,
    fileSize: file_data.size,
    service: file_data.service_object,
  };
  const body = {
    data_date: now.split('T')[0],
    active: false,
    service_object,
    name: `Version: ${$date(now, 'DATETIME_LONG')}`,
    asset: route.params.asset_id,
    imported: true,
  };
  await create_version({ body });
  state.is_versions_loading = true;
  const response = await set_versions();
  state.versions = response.data;
  state.is_versions_loading = false;
}

function getMenuItems(version) {
  const items = [];

  items.push({
    label: $t('Download'),
    icon: 'IconHawkDownload',
    on_click: () => onDownloadVersion(version),
  });
  if (!version.active) {
    items.push({
      label: $t('Activate version'),
      icon: 'IconHawkCheck',
      on_click: () => onActivateVersion(version),
    });
    items.push({
      label: $t('Delete'),
      icon: 'IconHawkTrash',
      on_click: () => onDeleteVersion(version),
    });
  }
  return items;
}
</script>

<template>
  <HawkModalTemplate
    :disable_footer="true"
    content_class="rounded-lg w-[600px]"
    @close="$emit('close')"
  >
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ $t("Versions") }}
      </div>
    </template>
    <template #default>
      <HawkLoader v-if="state.is_versions_loading" />
      <div v-else class="-m-2 h-[348px]">
        <Vueform
          ref="form$"
          size="sm"
          class="mb-7"
          :attachment_config="{
            meta: {
              service: 'pm',
            },
          }"
          :display-errors="false"
          :display-messages="false"
          :presets="['supress_errors']"
          @updated="onFormUpdated"
        >
          <FileElement
            :auto="true"
            :presets="['hawk_file_element']"
            :add-classes="{
              FileElement: {
                button: '!bg-white !border-0 hover:!scale-100 hover:!bg-gray-50 !font-medium !text-sm !text-gray-600 !px-3 !py-2',
              },
            }"
            name="file"
            accept=".xml,.xer,.mpp"
          >
            <template #upload-trigger>
              <IconHawkPlus class="inline -mt-0.5" />
              <span class="text-gray-600 ml-1">{{ $t("New Version") }}</span>
            </template>
          </FileElement>
        </Vueform>
        <div class="max-h-[252px] overflow-auto pr-4 scrollbar">
          <div v-for="version in state.versions" :key="version" class="pb-7">
            <div class="flex justify-between">
              <div class="flex flex-col justify-around">
                <h1 class="text-gray-900 text-sm font-semibold mb-1">
                  {{ $filters.truncate(version.name, 35) }}
                </h1>
                <div class="flex">
                  <HawkMembers :members="version.owner.uid" type="badge" />
                  <h1 class="text-gray-600 text-xs flex items-center pl-1">
                    {{ $date(version.created_at, 'DD MMMM YYYY hh:mma') }}
                  </h1>
                </div>
              </div>
              <div class="flex items-center">
                <HawkBadge v-if="version.active" color="green" class="h-9 my-auto mr-2">
                  {{ $t("Current Version") }}
                </HawkBadge>
                <HawkMenu position="fixed" :items="getMenuItems(version)">
                  <template #trigger>
                    <HawkButton icon type="outlined">
                      <IconHawkDotsVertical class="h-5 w-5" />
                    </HawkButton>
                  </template>
                  <template #item="{ item }">
                    {{ item.label }}
                  </template>
                </HawkMenu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </HawkModalTemplate>
</template>
