export function getDefaultView($t, schedule) {
  return {
    uid: '__default',
    data: {
      label: 'Default',
      grid_width: 760,
      group_by: [],
      resource_view: false,
      wbs_level_max: Number.MAX_SAFE_INTEGER,
      wbs_level: Number.MAX_SAFE_INTEGER,
      zoom_level: 2,
      layout: {
        grid: true,
        chart: true,
        resource_grid: false,
        resource_chart: false,
      },
      feature_visibility: {
        activity_name: true,
        links: !schedule.is_published,
        percentage_complete: true,
        float: false,
        today_line: true,
        data_date_line: false,
        deadline_line: true,
        overdue: true,
        critical_path: true,
        baseline: false,
      },
      columns: [
        ['left', 'general', $t('WBS'), 'wbs', true, false, 'text', 120, 120],
        ...(!schedule?.is_published ? [['center', 'general', $t('ID'), 'id', true, false, 'text', 80, 80]] : []),
        ['left', 'general', $t('Activity'), 'text', true, true, 'text', 300, 300],
        ...(schedule?.track_resources ? [['center', 'resources', $t('Resources'), 'resources', true, false, 'resources', 180, 180]] : []),
        ['center', 'dates', $t('Planned Start'), schedule.is_published ? 'planned_start' : 'start_date', true, false, 'date', 130, 130],
        ['center', 'durations', $t('Duration'), 'duration', true, false, 'number', 60, 60],
        ['center', 'dates', $t('Planned Finish'), schedule.is_published ? 'planned_finish' : 'end_date', true, false, 'date', 130, 130],
        ...(schedule.is_published ? [['center', 'percentages', $t('Progress'), 'progress', true, false, 'number', 100, 100]] : []),
        ...(schedule?.has_activity_weightages ? [['center', 'general', $t('Weight'), 'weight', true, false, 'weight', 80, 80]] : []),
      ].map(column =>
        ['align', 'group', 'label', 'name', 'resize', 'tree', 'type', 'width', 'min_width']
          .reduce((acc, cur, index) => {
            if (column[index] !== undefined)
              acc[cur] = column[index];

            return acc;
          }, {}),
      ),
      active_date_range: {
        from: undefined,
        to: undefined,
      },
      filters: {
        comments: false,
        critical: false,
        not_critical: false, // Could have gone for a better data structure to unify both critical and non-critical, but this will help in compatibility with the already saved views in prod.
        statuses: [],
        resources: [],
        progress_updates: '',
        priorities: ['high', 'medium', 'low'],
        progress: [0, 100],
        date_range: [null, null],
        activity: false, // task.type === $g.value.config.types.task
        // quick filters
        milestone: false, // this is both a regular one and quick one
        assigned_to_me: false,
        overdue: false,
        unread_mentions: false,
      },
    },
  };
}
